.blog .card {
  background-color: #FAFAFA;
}

.blog .category-6 {
  /* News */
  background-color: #f5c947;
}

.blog .category-7 {
  /* Interview */
  background-color: #ece1ee;
}

.blog .category-8 {
  /* AIRA Insights */
  background-color: #899fe2;
}

.blog .category-14 {
  /* Featured */
  background-color: #cad7f2;
}

.blog .badge {
  color: #404040;
  padding: 8px 16px;
  display: inline-block;
  margin-right: 5px;
}

.blog .post-title {
  margin-block: 16px;
}

.blog .post-content {
  color: #858481;
}

.blog .post-author {
  color: #4B4B4B;
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 16px;
}

.blog .post-thumbnail-container {
  border-radius: 10px;
  padding: 5px;
}

.blog .post-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* Category tabs */
.nav.nav-pills {
  gap: 16px;
}

.nav-pills .nav-link {
  border-radius: 50px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #212529;
  border: 1px solid #dee2e6;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease;
}

.nav-pills .nav-link.active {
  background-color: #212529;
  color: white;
  border: 1px solid #212529;
}

.nav-pills .nav-link:hover:not(.active) {
  background-color: #f8f9fa;
}

/* Mobile: Only 2 items per row */
@media (max-width: 439px) {
  .nav.nav-pills {
    gap: 6px;
    justify-content: space-between;
  }

  .nav-pills .nav-link {
    padding: 6px 14px;
    font-size: 12px;
  }
}