.post.post-container {
  padding-inline: 2rem;
}

.post .post-title {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 5rem;
  padding-top: 3rem;
}

.post ol,
.post ul {
  list-style: inside !important;
  padding-left: 20px;
}

.post article img {
  width: 100%;
  height: auto;
}

.post article video {
  width: 100%;
  height: auto;
}

.post a {
  text-decoration: none;
  color: #e96d59;
}

.post .post-footer-card {
  background-color: #fefefe;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.post .try-aira-btn {
  font-size: 24px;
  color: white;
  font-weight: bold;
  border: 4px solid white;
  border-radius: 50px;
  transition: 0.5s;
  background: linear-gradient(90deg, #86a6e9, #a067a8, #ed6245);
  background-size: 120% auto;
  padding-inline: 30px;
  width: fit-content;
}

.post .try-aira-btn:hover {
  cursor: pointer;
  background-position: 50% center !important;
  color: white !important;
  border-color: transparent;
}

.post .post-footer-card-body {
  border-top: 10px solid transparent;
  border-image: linear-gradient(90deg, #86a6e9, #a067a8, #ed6245);
  border-image-slice: 1;
  padding-block: 60px;
}

@media screen and (min-width: 1024px) {
  .post .post-title {
    padding-inline: 1rem;
  }

  .post.post-container {
    padding-inline: 12rem;
  }
}