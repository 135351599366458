.privacy-plugin .effective-date {
  color: #9c9c9c;
}

.privacy-plugin section {
  margin-top: 35px;
}

.privacy-plugin section h6 {
  margin-left: 6px;
  font-weight: bold;
}

.privacy-plugin .details p {
  margin: 0;
}

.privacy-plugin .list {
  margin-left: 20px;
}