.features-highlight .feature-card {
	background-color: #fafafa;
}

.features-highlight a {
	text-decoration: none;
}

.features-highlight a:hover {
	color: black !important;
}

.features-highlight i {
	color: white;
	padding: 12px;
	transition: all 0.3s ease-out;
	;
}

.features-highlight .fa-q {
	padding-inline: 13px;
}

.features-highlight .fa-play-circle {
	transform: scale(110%);
	transition: all 0.3s ease-out;
}

.features-highlight .card-description {
	color: #858481;
	display: inline-block;
	margin-bottom: 35px;
	font-size: 14px;
}

.features-highlight .card-image {
	bottom: 0;
	background: linear-gradient(180deg, hsla(0, 0%, 98%, 0), #fafafa 28.7%);
	height: 40%;
	width: 100%;
	transition: height 0.5s ease-out;
}

.features-highlight .card-image img {
	padding-block: 30px;
}

.features-highlight .card:hover .card-image {
	height: 18%;
}

.features-highlight button {
	font-size: 18px;
	color: white;
	font-weight: bold;
	border: 4px solid white;
	border-radius: 50px;
	width: 294px;
	transition: 0.5s;
	background: linear-gradient(90deg, #86a6e9, #a067a8, #ed6245);
	background-size: 120% auto;
}

.features-highlight button:hover {
	cursor: pointer;
	background-position: -52px center !important;
	color: white !important;
}